import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {  Routes, Route } from 'react-router-dom';

import { Navigate, Outlet } from "react-router-dom";
import { useAuth, AuthProvider } from "./hooks/useAuth.js";

import HomeDashboard from './components/HomeDashboard/HomeDashboard.js';
import GroupsPage from './components/GroupsPage/GroupsPage.js';
import LoginPage from './components/Login.js';
import BulkExpenseUpload from './components/BulkExpenseUpload/BulkExpenseUpload.js';
import SingleExpenseUpload from './components/SingleExpenseUpload/SingleExpenseUpload.js';
import ExpenseUpload from './components/ExpenseUpload/ExpenseUpload.js';
import OweTracker from './components/GroupAmountOwed/GroupAmountOwed.js'
import Settings from './components/Settings/Settings.js';

const ProtectedLayout = () => {
  const { user } = useAuth();
  if (!user) return <Navigate to="/" />;
  
  return <Outlet />;
};

const RootRedirect = () => {
  const { user } = useAuth();
  return user ? <Navigate to="/dashboard" /> : <LoginPage />;
};

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<RootRedirect />} />
        
        {/* All protected routes go inside this route */}
        <Route element={<ProtectedLayout />}>
          <Route path="/dashboard" element={<HomeDashboard />} />
          <Route path="/expenseUpload" element={<ExpenseUpload />} />
          <Route path="/bulk-expense-upload" element={<BulkExpenseUpload />} />
          <Route path="/single-expense-upload" element={<SingleExpenseUpload />} />
          <Route path="/groups" element={<GroupsPage />} />
          <Route path="/group/:groupId/group-dash" element={<OweTracker />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

export default App;