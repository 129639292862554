import React, { useState } from 'react';
import { useAuth } from "../hooks/useAuth.js";

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear any previous errors
    setError('');
    setLoading(true);

    const BASE_URL = process.env.REACT_APP_API_URL;
    
    try {
      const response = await fetch(`${BASE_URL}/login`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // If login is successful, handle the next steps (e.g., redirect to dashboard)
        await login({ userId: data.userId});
      } else {
        // If login failed, display error
        setError(data.message || 'Invalid credentials');
      }
    } catch (err) {
      console.error('Error logging in:', err);
      setError('Something went wrong. Please try again.');
    }
    setLoading(false);
  };

  return (
    <div className="login-container">
      <h1>Welcome to Wonder</h1>
      <form onSubmit={handleSubmit} className="auth-form">
        <div className="inner-element">
          <label>Email</label>
          <input
            id="email"
            type="text"
            placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="inner-element">
          <label>Password</label>
          <input
            id="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <br></br>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </div>
      </form>
      {loading}
    </div>
  );
};


export default LoginPage;
