import React, { useState } from 'react';
import Papa from 'papaparse';
import { useAuth } from "../../hooks/useAuth.js";
import './NewCsvConfigModal.css'; 

const CsvConfigModal = ({ isOpen, onClose, onConfigSaved }) => {
  const { user } = useAuth();

  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenseDate, setExpenseDate] = useState('');
  const [expenseDescription, setExpenseDescription] = useState('');
  const [configName, setConfigName] = useState('');
  const [isNegative, setIsNegative] = useState(false);

  const generateHeaders = (row) => {
    // Generate generic headers like A, B, C, ...
    return row.map((_, index) => String.fromCharCode(65 + index));
  };
  
  const parseCSV = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      header: false, // Start by assuming there are no headers
      complete: (results) => {
        const firstRow = results.data[0];
        const headersFromMeta = results.meta.fields;
  
        // Check if the first row has unique keys (indicates headers)
        const isLikelyHeader = firstRow && firstRow.every((value) => typeof value === "string" && value.trim() !== "");
  
        if (isLikelyHeader) {
          // Use the first row as headers
          const dataWithoutFirstRow = results.data.slice(1); // Remove the first row (header)
          setHeaders(firstRow);
          setData(
            dataWithoutFirstRow.map((row) =>
              firstRow.reduce((acc, header, index) => {
                acc[header] = row[index];
                return acc;
              }, {})
            )
          );
        } else if (firstRow) {
          // No headers detected, generate generic ones
          const generatedHeaders = generateHeaders(firstRow);
          setHeaders(generatedHeaders);
          setData(
            results.data.map((row) =>
              row.reduce((acc, value, index) => {
                acc[generatedHeaders[index]] = value;
                return acc;
              }, {})
            )
          );
        } else {
          setError("Unable to parse the CSV file.");
          setData([]);
          setHeaders([]);
        }
      },
      error: (error) => {
        console.error("Error parsing CSV: ", error);
        setError("Failed to parse CSV.");
      },
    });
  };
  

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile) {
      setFile(uploadedFile);
      parseCSV(uploadedFile);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      console.error('User is not authenticated');
      return;
    }

    const configData = {
      user_id: user.userId, 
      date_column: expenseDate,
      amount_column: expenseAmount,
      desc_column: expenseDescription,
      configuration_name: configName,
      is_negative: isNegative,
    };

    const BASE_URL = process.env.REACT_APP_API_URL;

    try {
      const response = await fetch(`${BASE_URL}/save-configuration`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(configData),
      });

      if (response.ok) {
        console.log('Configuration saved successfully');
        if (onConfigSaved) onConfigSaved();
        setFile(null);
        setData([]);
        onClose();
      } else {
        console.error('Failed to save configuration');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error saving configuration:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <h2>CSV Configuration</h2>
        
        {error && <div className="error-message">{error}</div>}
        
        <div className="file-upload-section">
          <input type="file" accept=".csv" onChange={handleFileChange} id="csv-file" className="file-input" />
          <label htmlFor="csv-file" className="file-label">
            {file ? file.name : 'Choose CSV file'}
          </label>
        </div>

        {data.length > 0 ? (
          <div className="config-container">
            <div className="preview-section">
              <h3>Preview</h3>
              <div className="table-wrapper">
                <table className="preview-table">
                  <thead>
                    <tr>
                      {headers.map((header) => (
                        <th key={header}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.slice(0, 3).map((row, index) => (
                      <tr key={index}>
                        {headers.map((header) => (
                          <td key={header}>{row[header]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="config-section">
              <h3>Column Mapping</h3>
              <div className="config-grid">
                <div className="config-item">
                  <label>Transaction Date</label>
                  <select value={expenseDate} onChange={(e) => setExpenseDate(e.target.value)}>
                    <option value="">Select column</option>
                    {headers.map((key) => (
                      <option key={key} value={key}>{key}</option>
                    ))}
                  </select>
                </div>

                <div className="config-item">
                  <label>Amount</label>
                  <select value={expenseAmount} onChange={(e) => setExpenseAmount(e.target.value)}>
                    <option value="">Select column</option>
                    {headers.map((key) => (
                      <option key={key} value={key}>{key}</option>
                    ))}
                  </select>
                </div>

                <div className="config-item">
                  <label>Description</label>
                  <select value={expenseDescription} onChange={(e) => setExpenseDescription(e.target.value)}>
                    <option value="">Select column</option>
                    {headers.map((key) => (
                      <option key={key} value={key}>{key}</option>
                    ))}
                  </select>
                </div>

                <div className="config-item checkbox-item">
                  <label>
                  <span>Expenses are negative values</span>
                    <input
                      type="checkbox"
                      checked={isNegative}
                      onChange={(e) => setIsNegative(e.target.checked)}
                    />
                  </label>
                </div>

                <div className="config-item">
                  <label>Configuration Name</label>
                  <input 
                    type="text" 
                    value={configName} 
                    onChange={(e) => setConfigName(e.target.value)}
                    placeholder="e.g., bank_of_america_checking"
                    className="config-name-input"
                  />
                </div>
              </div>

              <button onClick={handleSubmit} className="save-button">
                Save Configuration
              </button>
            </div>
          </div>
        ) : (
          <p className="no-data">Upload a CSV file to begin configuration</p>
        )}
      </div>
    </div>
  );
};

export default CsvConfigModal;
