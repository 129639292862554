import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useAuth } from "../../hooks/useAuth.js";

// Helper function to generate generic headers
const generateHeaders = (row) => {
    return row.map((_, index) => String.fromCharCode(65 + index)); // Generates A, B, C, D, etc.
};

const CsvUploader = ({ onUpload, selectedConfig, groups}) => {
    const { user } = useAuth();
    const [error, setError] = useState('');
    const [headers, setHeaders] = useState([]);
    const [loading, setLoading] = useState(false);

    const BASE_URL = process.env.REACT_APP_API_URL;

    const groupsMap = groups.reduce((acc, group) => {
        acc[group.group_id] = group.group_name;
        return acc;
    }, {});

    const user_options = groups.map(item => ({
        group_id: item.group_id,
        user_name: item.user_name,
        user_id: item.id
    }));

    const distinctUserOptions = [...new Set(user_options.map(item => item.user_id))].map(id => user_options.find(item => item.user_id === id));

    const fetchPersonalGroupId = async () => {
        const userData = {
            user_id: user.userId,
        };

        try {
            const response = await fetch(`${BASE_URL}/get-personal-group-id`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(userData),
            });

            const data = await response.json();
            return data[0].personal_group_id;
        } catch (err) {
            console.error('Error fetching personal group ID:', err);
            throw err;
        }
    };

    const parseCSV = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                skipEmptyLines: true,
                header: false, // Start by assuming no headers
                complete: (results) => {
                    const firstRow = results.data[0];

                    // Check if the first row looks like a header
                    const isLikelyHeader = firstRow && firstRow.every((value) => typeof value === "string" && value.trim() !== "");

                    let parsedHeaders = [];
                    let parsedData = [];

                    if (isLikelyHeader) {
                        // Use the first row as headers
                        parsedHeaders = firstRow;
                        parsedData = results.data.slice(1).map((row) =>
                            firstRow.reduce((acc, header, index) => {
                                acc[header] = row[index];
                                return acc;
                            }, {})
                        );
                    } else if (firstRow) {
                        // No headers detected, generate generic ones
                        parsedHeaders = generateHeaders(firstRow);
                        parsedData = results.data.map((row) =>
                            row.reduce((acc, value, index) => {
                                acc[parsedHeaders[index]] = value;
                                return acc;
                            }, {})
                        );
                    } else {
                        return reject("Unable to parse the CSV file.");
                    }

                    resolve({ headers: parsedHeaders, data: parsedData });
                },
                error: (error) => {
                    reject(error.message || "Failed to parse CSV.");
                },
            });
        });
    };

    const predictGroups = async (expenses) => {
        try {
            // Direct call to API Gateway URL
            const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(expenses.map(expense => ({
                    amount: expense.amount,
                    description: expense.description,
                    date: expense.date,
                    user_id: parseInt(user.userId, 10)
                }))),
            });
            
            const data = await response.json();
            return data.predictions;
        } catch (err) {
            console.error('Error predicting groups:', err);
            throw err;
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file || !selectedConfig) return;
    
        setLoading(true);
        setError('');
    
        try {
            const personalGroupId = await fetchPersonalGroupId();
            const { headers: parsedHeaders, data: parsedData } = await parseCSV(file);
            setHeaders(parsedHeaders);
    
            const formatDate = (inputDate) => {
                const parsedDate = new Date(inputDate);
                if (isNaN(parsedDate)) {
                    throw new Error(`Invalid date: ${inputDate}`);
                }
                // Adjust for timezone offset
                parsedDate.setMinutes(parsedDate.getMinutes() - parsedDate.getTimezoneOffset());
                return parsedDate.toISOString().split('T')[0];
            };
    
            // Prepare expenses data
            const expensesData = parsedData.map(row => {
                const amount = parseFloat(row[selectedConfig[0].expense_amount_col_name]);
                const isNegative = selectedConfig[0].expense_amount_is_negative;
                const amount_formatted = isNegative ? amount * -1 : amount;
                const description = row[selectedConfig[0].expense_description_col_name];
                
                let formattedDate;
                try {
                    formattedDate = formatDate(row[selectedConfig[0].expense_date_col_name]);
                } catch (error) {
                    console.error(error);
                    throw new Error(`Date parsing error for row: ${JSON.stringify(row)}`);
                }

                return {
                    amount: amount_formatted,
                    description: description,
                    date: formattedDate
                };
            });

            // Get all group predictions in one batch
            const groupPredictions = await predictGroups(expensesData);

            // Process the results
            const expenses = expensesData.map((expense, index) => {
                const predictedGroupId = groupPredictions[index]?.predicted_group_id || personalGroupId;
                const groupName = groupsMap[predictedGroupId] || 'Personal';

                let expenseUserBreakdown;
                if (groupName === 'Personal') {
                    expenseUserBreakdown = [{
                        user_id: parseInt(user.userId, 10),
                        amount: expense.amount,
                    }];
                } else {
                    const splitAmount = expense.amount / distinctUserOptions.length;
                    expenseUserBreakdown = distinctUserOptions.map(member => ({
                        user_id: parseInt(member.user_id, 10),
                        amount: splitAmount,
                    }));
                }

                return {
                    date: expense.date,
                    description: expense.description,
                    amount: expense.amount,
                    group_name: groupName,
                    group_id: predictedGroupId,
                    category: null,
                    expense_user_breakdown: expenseUserBreakdown,
                    settled: groupName === 'Personal',
                };
            });

            onUpload(expenses);
        } catch (error) {
            console.error("Error processing CSV:", error);
            setError(error.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };    

    return (
        <div>
            <input 
                type="file" 
                accept=".csv" 
                onChange={handleFileChange} 
                disabled={!selectedConfig || selectedConfig.length === 0}
            />
            {!selectedConfig || selectedConfig.length === 0 && 
                <p style={{ color: 'gray' }}>Please select a configuration before uploading a CSV file</p>
            }
            {loading && <p>Loading...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default CsvUploader;
