import React, { useState, useEffect } from 'react';
import Toolbar from '../Toolbar/Toolbar.js';
import { useAuth } from "../../hooks/useAuth.js";
import './Settings.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
dayjs.extend(utc);

const Settings = () => {
    const { user } = useAuth();
    const [recurringExpenses, setRecurringExpenses] = useState([]);

    const BASE_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        // Fetch recurring expenses from your backend
        fetchRecurringExpenses();
    }, []);

    const fetchRecurringExpenses = async () => {
        try {
            const response = await fetch(`${BASE_URL}/get-recurring-expenses`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: user.userId }),
            });
            const data = await response.json();
            setRecurringExpenses(data);
        } catch (error) {
            console.error('Error fetching recurring expenses:', error);
        }
    };

    const handleDelete = async (expenseId) => {
        try {
            await fetch(`${BASE_URL}/delete-recurring-expense`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ expense_id: expenseId }),
            });
            // Refresh the list
            fetchRecurringExpenses();
        } catch (error) {
            console.error('Error deleting recurring expense:', error);
        }
    };

    const handleModify = async (expense) => {
        // Add your modification logic here
    };

    return (
        <div>
            <Toolbar />
        <div className="settings-container">
            <h1>Settings</h1>
            <section className="recurring-expenses">
                <h2>Recurring Expenses</h2>
                {recurringExpenses.length === 0 ? (
                    <p>No recurring expenses found</p>
                ) : (
                    <ul className="expense-list">
                        {recurringExpenses.map((expense) => (
                            <li key={expense.id} className="expense-item">
                                <div className="expense-details">
                                    <h3>{expense.description}</h3>
                                    <p>Amount: ${expense.amount}</p>
                                    <p>Group Name: {expense.group_name}</p>
                                    <p>Next Expense Date: {dayjs.utc(expense.next_expense_date).format('YYYY-MM-DD')}</p>
                                </div>
                                <div className="expense-actions">
                                    {/* <button onClick={() => handleModify(expense)}>
                                        Modify
                                    </button> */}
                                    <button onClick={() => handleDelete(expense.id)}>
                                        Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                </section>
            </div>
        </div>  
    );
};

export default Settings;