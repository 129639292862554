import React, { useEffect, useState } from 'react';
import './SavedConfigDropdown.css'; 
import { useAuth } from "../../hooks/useAuth.js";

const ConfigDropdown = ({ selectedConfig, setSelectedConfig, refreshTrigger }) => {
  
  const [configs, setConfigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useAuth();

  const BASE_URL = process.env.REACT_APP_API_URL;

  const configData = {
    user_id: user.userId,
  };

  useEffect(() => {
    const fetchConfigs = async () => {
      try {
        const response = await fetch(`${BASE_URL}/get-csv-configurations`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(configData),
          }); 
        if (!response.ok) throw new Error('Failed to fetch configurations');
        const data = await response.json();

        setConfigs(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchConfigs();
  }, [refreshTrigger]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleChange = (event) => {
    const selected_config = configs.filter(c => c.config_name===event.target.value);
    setSelectedConfig(selected_config);
  };

  return (
    <div className="config-dropdown-container">
    <label htmlFor="config-dropdown" className="config-label">
        Saved Configurations:
      </label>
      <select 
        value={selectedConfig ? selectedConfig[0]?.config_name : ""} 
        onChange={handleChange} 
        id="config-dropdown" 
        className="config-select"
      >
        <option value="">Select Configuration</option>
        {configs.map((config) => (
          <option key={config.config_name} value={config.config_name}>
            {config.config_name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ConfigDropdown;
