import React, { useState, useEffect } from 'react';
import Toolbar from '../Toolbar/Toolbar.js';
import CsvConfigModal from '../NewCsvConfigModal/NewCsvConfigModal.js';
import EditableCsvDataTable from '../EditableCsvDataTable/EditableCsvDataTable.js'
import ExpenseCarousel from '../CsvCarousel/CsvCarousel.js';
import CsvUploader from '../CsvUpload/CsvUpload.js';
import ConfigDropdown from '../SavedConfigDropdown/SavedConfigDropdown.js';
import { useAuth } from "../../hooks/useAuth.js";
import './BulkExpenseUpload.css'; 


const BulkExpenseUpload = () => {
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [configOptions, setConfigOptions] = useState([]);
    const [selectedConfig, setSelectedConfig] = useState('');
    const [loading, setLoading] = useState(true);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [configRefreshTrigger, setConfigRefreshTrigger] = useState(0);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const BASE_URL = process.env.REACT_APP_API_URL;

    const warmupMLService = async () => {
      try {
        // Send a health check request to warm up the Lambda
        const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        
        const data = await response.json();
        console.log('ML service warm-up status:', data.status);
      } catch (error) {
        console.error('Failed to warm up ML service:', error);
      }
    };
  
    useEffect(() => {
      warmupMLService();
    }, []);

    const categories = [
      'Groceries', 
      'Dining Out', 
      'Household Supplies', 
      'Utilities', 
      'Subscriptions',
      'Insurance',
      'Gas', 
      'Travel',
      'Gifts',
      'Exercise Nutrition',
      'Entertainment & Activities',
      'Personal',
      'Other'];

    const handleUpload = (uploadedExpenses) => {
      setExpenses(uploadedExpenses);
    };

    const handleUpdate = (index, updatedExpense) => {
      const newExpenses = [...expenses];
      newExpenses[index] = updatedExpense;
      setExpenses(newExpenses);
    };

    const handleDeleteExpense = (expenseIndex) => {
      const updatedExpenses = expenses.filter((_, index) => index !== expenseIndex);
      setExpenses(updatedExpenses);
    };

  const handleSubmitExpenses = async () => {

    setIsSubmitting(true);
  
    try {
      const response = await fetch(`${BASE_URL}/bulk-write-expenses`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_id: user.userId, expenses }),
      });
  
      if (response.ok) {
        alert('Expenses submitted successfully!');
        setExpenses([]); // Clear the expenses if submission is successful
      } else {
        const errorData = await response.json();
        console.error('Error submitting expenses:', errorData);
        alert('Failed to submit expenses. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting expenses.');
    } finally {
      setIsSubmitting(false); // Add this to reset the state
    }
  };
  

    const { user } = useAuth();

    const userData = {
      user_id: user.userId,
    };

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${BASE_URL}/get-user-groups`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(userData),
          }); 
        const group_data = await response.json();
        setGroupOptions(group_data);

      } catch (error) {
        console.error('Error fetching groups:', error);
      } finally {
        setLoading(false);
      }
    }; 

    fetchGroups();
  }, []);

    return (
      <div>
        <Toolbar />
        <div className="csv-upload-container">
          <h1>Bulk CSV Expense Upload</h1>
          <button className="upload-button" onClick={openModal}>
              Upload New Configuration
          </button>
          <ConfigDropdown 
            selectedConfig={selectedConfig} 
            setSelectedConfig={setSelectedConfig}
            refreshTrigger={configRefreshTrigger} />
          <CsvUploader onUpload={handleUpload} selectedConfig={selectedConfig} groups={groupOptions} />
          <div className="expense-carousel-container"> 
          <ExpenseCarousel 
            expenses={expenses} 
            onUpdate={handleUpdate} 
            onDelete={handleDeleteExpense} 
            groups={groupOptions}
            categories={categories}/>
          <button  
            className="submit-button" 
            disabled={expenses.length === 0 || isSubmitting}
            onClick={handleSubmitExpenses}>
            {isSubmitting ? 'Submitting...' : 'Submit Expenses'}
          </button>
          </div>
          <CsvConfigModal 
            isOpen={isModalOpen} 
            onClose={closeModal}
            onConfigSaved={() => setConfigRefreshTrigger(prev => prev + 1)}
          />
        </div>
      </div>
    );
};

export default BulkExpenseUpload;