import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Toolbar from "../Toolbar/Toolbar.js";
import { useAuth } from "../../hooks/useAuth.js";
import "./HomeDashboard.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {

  const [metrics, setMetrics] = useState({
    totalExpenses: 0,
    biggestCategory: { category: "", amount: 0 },
    biggestExpense: { amount: 0, category: "", description: "" },
    groupExpensePercentages: [],
  });
  const [categoryChartData, setCategoryChartData] = useState({
    labels: [],
    datasets: [],
  });

  const [timeRange, setTimeRange] = useState("90D");
  const [filteredData, setFilteredData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [expenses, setExpenses] = useState([]);

  const BASE_URL = process.env.REACT_APP_API_URL;
  const { user } = useAuth();

  const userData = {
    user_id: user.userId,
  };

  const warmupMLService = async () => {
    try {
      // Send a health check request to warm up the Lambda
      const response = await fetch(`${process.env.REACT_APP_API_GATEWAY_URL}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      const data = await response.json();
      console.log('ML service warm-up status:', data.status);
    } catch (error) {
      console.error('Failed to warm up ML service:', error);
    }
  };

  useEffect(() => {
    warmupMLService();
  }, []);

  const fetchMetricCardData = async () => {
    try {
      const response = await fetch(`${BASE_URL}/get-dashboard-metric-card-data`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });
      const expenses = await response.json();
      setExpenses(expenses);

      const totalExpenses = expenses.reduce((sum, expense) => sum + expense.amount_portion, 0);

      const categoryTotals = expenses.reduce((acc, expense) => {
        acc[expense.category] = (acc[expense.category] || 0) + expense.amount_portion;
        return acc;
      }, {});

      const [biggestCategory, biggestCategoryAmount] = Object.entries(categoryTotals).reduce(
        (max, [category, amount]) => (amount > max[1] ? [category, amount] : max),
        ["", 0]
      );

       // Prepare data for Doughnut chart
       const sortedCategoryTotals = Object.entries(categoryTotals)
        .sort(([, amountA], [, amountB]) => amountB - amountA); // Sort by total in descending order

        const chartLabels = sortedCategoryTotals.map(([category]) => category); // Extract sorted labels
        const chartData = sortedCategoryTotals.map(([, amount]) => amount);

       setCategoryChartData({
         labels: chartLabels,
         datasets: [
           {
             label: "Expenses by Category",
             data: chartData,
             backgroundColor: [
               "#FF6384",
               "#36A2EB",
               "#FFCE56",
               "#4BC0C0",
               "#9966FF",
               "#FF9F40",
             ],
             hoverBackgroundColor: [
               "#FF6384",
               "#36A2EB",
               "#FFCE56",
               "#4BC0C0",
               "#9966FF",
               "#FF9F40",
             ],
           },
         ],
       });

      const biggestExpense = expenses.reduce(
        (max, expense) => {
          if (expense.amount_portion > max.amount) {
            return {
              amount: expense.amount_portion,
              category: expense.category || "No category",
              description: expense.description || "No description",
            };
          }
          return max; // If current expense isn't bigger, return previous max
        },
        { amount: 0, description: "" } // Initial value
      );


      const groupTotals = expenses.reduce((acc, expense) => {
        acc[expense.group_name] = (acc[expense.group_name] || 0) + expense.amount_portion;
        return acc;
      }, {});
      const groupExpensePercentages = Object.entries(groupTotals).map(([group_name, amount]) => ({
        group_name,
        percentage: ((amount / totalExpenses) * 100).toFixed(2),
      }));

      setMetrics({
          totalExpenses,
          biggestCategory: { category: biggestCategory, amount: biggestCategoryAmount },
          biggestExpense,
          groupExpensePercentages,
        });
    } catch (error) {
      console.log(`Error fetching metric card data: ${error}`);
    }
  };

  useEffect(() => {
    fetchMetricCardData();
  }, []);

  const filterExpensesByTimeRange = (range) => {
    const today = new Date();
    let startDate;

    // Determine start date based on selected time range
    if (range === "30D") {
      startDate = new Date(today.setDate(today.getDate() - 30));
    } else if (range === "60D") {
      startDate = new Date(today.setDate(today.getDate() - 60));
    } else if (range === "90D") {
      startDate = new Date(today.setDate(today.getDate() - 90));
    }

    // Filter expenses by date
    const filtered = expenses.filter((expense) => {
      const expenseDate = new Date(expense.expense_date); // Ensure expense_date exists
      return expenseDate >= startDate;
    });

    setFilteredData(filtered);

    // Prepare chart data for filtered expenses
    const dailyTotals = filtered.reduce((acc, expense) => {
      const date = new Date(expense.expense_date).toISOString().split("T")[0];
      acc[date] = (acc[date] || 0) + expense.amount_portion;
      return acc;
    }, {});

    const sortedDates = Object.keys(dailyTotals).sort((a, b) => new Date(a) - new Date(b));
    const dailyAmounts = sortedDates.map((date) => dailyTotals[date]);

    // Calculate 7-day moving average
    const movingAverage = dailyAmounts.map((amount, index) => {
      if (index < 6) return null; // First 6 days will have no average
      const slice = dailyAmounts.slice(index - 6, index + 1);
      const avg = slice.reduce((sum, val) => sum + val, 0) / 7;
      return avg;
    });

    setChartData({
      labels: sortedDates,
      datasets: [
        {
          label: "Daily Expenses",
          data: dailyAmounts,
          fill: false,
          borderColor: "rgba(54, 162, 235, 0.2)", // Light blue with opacity
          backgroundColor: "rgba(54, 162, 235, 0.1)",
          tension: 0.1,
          order: 2, // This will put it behind the moving average line
        },
        {
          label: "7-Day Moving Average",
          data: movingAverage,
          fill: false,
          borderColor: "rgba(112,128,144, 1)", //112,128,144
          backgroundColor: "rgba(112,128,144, 0.5)",
          tension: 0.4,
          order: 1, // This will put it in front
        },
      ],
    });
  };

  useEffect(() => {
    filterExpensesByTimeRange(timeRange);
  }, [timeRange, expenses]);
  
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    elements: {
      point: {
        radius: 0, // This removes the points on the lines
        hitRadius: 10, // This keeps the hover effect
      },
    },
  };

  const doughnutOptions = {
    ...chartOptions,
    plugins: {
      ...chartOptions.plugins,
      legend: {
        position: 'bottom',
        align: 'center',
      },
    },
    layout: {
      padding: 20
    }
  };

  return (
    <div className='overall-container'>
        <Toolbar />
    <div className="dashboard-container">
        <h1 style={{ textAlign: 'center' }}>Home Dashboard</h1>
      {/* Metrics Cards */}
      <div className="metrics-grid">
        {/* Total Expenses */}
        <div className="metrics-card">
          <p className="metrics-title">Total Expenses (L90D)</p>
          <h2 className="metrics-value">${metrics.totalExpenses.toFixed(2).toLocaleString()}</h2>
        </div>

        {/* Biggest Category */}
        <div className="metrics-card">
          <p className="metrics-title">Biggest Category (L90D)</p>
          <h2 className="metrics-value">
            {metrics.biggestCategory.category}
          </h2>
          <p className="metrics-subtitle">Amount: ${metrics.biggestCategory.amount.toFixed(2).toLocaleString()}</p>
        </div>

        {/* Biggest Expense */}
        <div className="metrics-card">
          <p className="metrics-title">Biggest Expense (L90D)</p>
          <h2 className="metrics-value">
            ${metrics.biggestExpense.amount.toFixed(2).toLocaleString()}
          </h2>
          <p className="metrics-subtitle">Category: {metrics.biggestExpense.category}</p>
          <p className="metrics-subtitle">({metrics.biggestExpense.description})</p>
        </div>

        {/* Group Expense Percentages */}
        <div className="metrics-card">
          <p className="metrics-title">Group Expense Percentages (L90D)</p>
          <ul>
            {metrics.groupExpensePercentages.map(({ group_name, percentage }) => (
              <li key={group_name}>
                {group_name}: {percentage}%
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Charts Section */}
      <div className="charts-grid">
        <div className="finance-insights">
            <div className="finance-header">
                <h3>Finance Insights</h3>
                <div className="time-range">
                    {["30D", "60D", "90D"].map((range) => (
                    <button
                        key={range}
                        className={range === timeRange ? "active" : ""}
                        onClick={() => setTimeRange(range)}>
                        {range}
                    </button>))}
                </div>
            </div>
            <div className="chart-wrapper">
                <div className="chart-container">
                    {chartData ? (
                    <Line data={chartData} options={chartOptions} />
                    ) : (
                    <p>Loading chart...</p>
                    )}
                </div>
            </div>
        </div>

        <div className="expense-overview">
            <h3>Expense Overview</h3>
            <div className="chart-wrapper">
                <Doughnut data={categoryChartData} />
            </div>
        </div>
    </div>

      {/* Budget Suggestions and Integrations */}
      <div className="bottom-grid">
        {/* Budget Suggestions */}
        <div className="budget-suggestions">
          <h3>Smart Budget Suggestions</h3>
          <ul>
            {[
              { domain: "Placeholder 1" },
              { domain: "Placeholder 2" },
            ].map((item, index) => (
              <li key={index} className="budget-item">
                <span>{item.domain}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
