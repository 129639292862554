import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth.js";
import './Toolbar.css';

const Toolbar = () => {
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };

    const { logout } = useAuth();

    const handleLogout = () => {
      logout();
    };

    return (
      <div className="toolbar-container">
        <div className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </div>
        <div className={`toolbar ${isMenuOpen ? 'open' : ''}`}>
          <div className="toolbar-header">
            <button className="toolbar-close-button" onClick={toggleMenu}>
              &times;
            </button>
          </div>
          <div className="toolbar-items">
            <Link to="/dashboard" onClick={toggleMenu}>Dashboard</Link>
            <Link to="/expenseUpload" onClick={toggleMenu}>Expense Upload</Link>
            <Link to="/groups" onClick={toggleMenu}>Groups</Link>
            <Link to="/settings" onClick={toggleMenu}>Settings</Link>
            <button onClick={handleLogout}>
                Logout
            </button>
          </div>
        </div>
      </div>
    );
};


export default Toolbar;
