// ConfirmDeleteModal.js
import React from 'react';
import './ConfirmDeleteModal.css'; // You can style it here

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null; // If modal is closed, render nothing

    return (
        <div className="modal-overlay">
            <div className="modal-container">
                <h2>Are you sure you want to delete this expense?</h2>
                <div className="modal-actions">
                    <button onClick={onConfirm}>Yes</button>
                    <button onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDeleteModal;
